// button layout
.btn_layout {
  [class*='btn'] + [class*='btn'] {
    margin-left: 8px;
  }
}
.btn_layout.right {
  float: right;
}

// button
[class*='btn'] {
  transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border 0.2s ease-in-out;
  word-break: keep-all;
  i + span {
    margin-right: 4px;
  }
  span + i {
    margin-left: 4px;
  }
  &:hover:enabled {
    transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out,
      border 0.2s ease-in-out;
  }
  &:disabled:not(.btn_txt):not(.btn_txt_bk) {
    background-color: #aeb3b8;
    border: none;
    cursor: auto;
    span {
      color: #d5d8db;
    }
  }
}

// size
[class*='btn'][class*='_l']:not(.btn_layout) {
  @include typo_title1;
  padding: 14px 24px;
  border-radius: 8px;
}
[class*='btn'][class*='_m'] {
  @include typo_body1;
  padding: 8px 16px;
  border-radius: 4px;
}
[class*='btn'][class*='_primary'][class*='_m'] {
  font-weight: 600;
}
[class*='btn'][class*='_s']:not(.btn_second_m) {
  @include typo_body1;
  padding: 4px 12px;
  font-weight: 600;
  border-radius: 4px;
}

// type
[class*='btn_primary'] {
  background-color: #222e59;
  span {
    color: $color_gray11;
  }
  &:hover:enabled {
    background-color: $color_bgray3;
    span {
      color: $color_white;
    }
  }
}
[class*='btn_second'] {
  border: 1px solid #4876ee;
  span {
    color: #4876ee;
  }
  &:hover:enabled {
    background-color: #edf0f4;
    border-color: #4876ee;
    span {
      color: #4876ee;
    }
  }
}
[class*='btn_ghost'] {
  border: 1px solid $color_gray5;
  span {
    color: $color_gray3;
  }
  &:hover:enabled {
    border-color: $color_gray4;
  }
  &:disabled {
    background-color: $color_gray8;
    cursor: auto;
    span {
      color: $color_gray7;
    }
  }
}
[class*='btn_ghost_s'] {
  border-color: $color_gray6;
  span {
    color: $color_gray4;
  }
}
.btn_radius {
  padding: 7px 24px;
  border: 1px solid #edf0f4;
  border-radius: 100px;
  background-color: #edf0f4;
  &.wh {
    color: $color_gray11;
    border-color: $color_gray6;
    background: transparent;
    button {
      color: inherit;
    }
  }
  &.active {
    background-color: #4876ee;
    border: 1px solid #4876ee;
    color: $color_white;
  }
}
[class*='btn_txt'] {
  vertical-align: middle;
  font-weight: 600;
  line-height: 150%;
  i {
    vertical-align: sub;
  }
  span {
    color: $color_gray6;
  }
  i + span {
    margin-left: 8px;
  }
  span + i {
    margin-left: 8px;
  }
  &[class*='bk'] {
    span {
      color: $color_bgray1;
    }
  }
  &[class*='wh'] {
    span {
      color: $color_white;
    }
  }
  &:hover:enabled {
    opacity: 0.7;
  }
}
[class*='btn_com'] {
  @include typo_body3;
  padding: 5px 8px;
  color: #f45546;
  border: 1px solid #f45546;
  border-radius: 4px;
}
[class*='btn_com'][class*='color2'] {
  color: $color_bgray4;
  border-color: $color_bgray4;
}
[class*='btn_com'][class*='color3'] {
  color: $color_point6;
  border-color: $color_point6;
}

// ico
[class*='ico'] {
  display: inline-block;
  vertical-align: middle;
}
[class*='ico'][class*='pin'] {
  background: url('../../images/ico/ico_pin.png') no-repeat center center;
}
[class*='ico'][class*='fix'] {
  background: url('../../images/ico/ico_fix_board.png') no-repeat center center;
}
[class*='ico'][class*='del'] {
  background: url('../../images/ico/ico_del_input.png') no-repeat center center;
}
[class*='ico'][class*='first'] {
  background: url('../../images/ico/ico_first_page.png') no-repeat center center;
}
[class*='ico'][class*='prev'] {
  background: url('../../images/ico/ico_prev_page.png') no-repeat center center;
}
[class*='ico'][class*='prev_bk'] {
  background: url('../../images/ico/ico_prev_btn.png') no-repeat center center;
}
[class*='ico'][class*='next'] {
  background: url('../../images/ico/ico_next_page.png') no-repeat center center;
}
[class*='ico'][class*='next_wh'] {
  background: url('../../images/ico/ico_next_btn_wh.png') no-repeat center center;
}
[class*='ico'][class*='next_bk'] {
  background: url('../../images/ico/ico_next_page_bk.png') no-repeat center center;
}
[class*='ico'][class*='last'] {
  background: url('../../images/ico/ico_last_page.png') no-repeat center center;
}
[class*='ico'][class*='pop_close'] {
  background: url('../../images/ico/btn_close_popup.png') no-repeat center center;
}
[class*='ico'][class*='pop_close2'] {
  background: url('../../images/ico/btn_close_popup2.png') no-repeat center center;
}
[class*='ico'][class*='board_list'] {
  background: url('../../images/ico/ico_prev_board.png') no-repeat center center;
}
[class*='ico'][class*='more'] {
  background: url('../../images/ico/btn_more.png') no-repeat center center;
}
[class*='ico'][class*='go_list'] {
  background: url('../../images/ico/ico_list.png') no-repeat center center;
}
[class*='ico'][class*='prev_board'] {
  background: url('../../images/ico/btn_prev_board.png') no-repeat center center;
}
[class*='ico'][class*='down_arrow'] {
  background: url('../../images/ico/ico_down_arrow_wh.png') no-repeat center center;
}
[class*='ico'][class*='download'] {
  background: url('../../images/ico/ico_download_gnb.png') no-repeat center center;
}
[class*='ico'][class*='download_bk'] {
  background: url('../../images/ico/ico_download_bk.png') no-repeat center center;
}
[class*='ico'][class*='download_pp'] {
  background: url('../../images/ico/ico_download_gnb_pp.png') no-repeat center center;
}
[class*='ico'][class*='progress_close'] {
  background: url('../../images/ico/ico_close_popup.png') no-repeat center center;
}
[class*='ico'][class*='down_pdf'] {
  background: url('../../images/ico/btn_download_pdf.png') no-repeat center center;
}
[class*='ico'][class*='comi'] {
  background: url('../../images/ico/ico_comi.png') no-repeat center center;
}
[class*='ico'][class*='comc'] {
  background: url('../../images/ico/ico_comc.png') no-repeat center center;
}
[class*='ico'][class*='comr'] {
  background: url('../../images/ico/ico_comr.png') no-repeat center center;
}
[class*='ico'][class*='comt'] {
  background: url('../../images/ico/ico_comt.png') no-repeat center center;
}
[class*='ico'][class*='accoplus'] {
  background: url('../../images/ico/btn_accirdion_plus.png') no-repeat center center;
}
[class*='ico'][class*='accodown'] {
  background: url('../../images/ico/btn_accirdion_down.png') no-repeat center center;
}
[class*='ico'][class*='accoup'] {
  background: url('../../images/ico/btn_accirdion_up.png') no-repeat center center;
}
[class*='ico'][class*='tel'] {
  background: url('../../images/ico/ico_tel.png') no-repeat center center;
}
[class*='ico'][class*='sendmail'] {
  background: url('../../images/ico/ico_mail_inquiry.png') no-repeat center center;
}
[class*='ico'][class*='prev_gall'] {
  background: url('../../images/ico/btn_prev_gallery.png') no-repeat center center;
}
[class*='ico'][class*='next_gall'] {
  background: url('../../images/ico/btn_next_gallery.png') no-repeat center center;
}
[class*='ico'][class*='tooltip'] {
  background: url('../../images/ico/ico_tooltip.png') no-repeat center center;
}
[class*='ico'][class*='reset'] {
  background: url('../../images/ico/ico_reset_gnb.png') no-repeat center center;
}
[class*='ico'][class*='link'] {
  background: url('../../images/ico/ico_link.png') no-repeat center center;
}
[class*='ico'][class*='shinhan'] {
  background: url('../../images/ico/ico_partner_shinhan.png') no-repeat center center;
}
[class*='ico'][class*='woori'] {
  background: url('../../images/ico/ico_partner_woori.png') no-repeat center center;
}
[class*='ico'][class*='nonghyb'] {
  background: url('../../images/ico/ico_partner_nh.png') no-repeat center center;
}
[class*='ico'][class*='lotte'] {
  background: url('../../images/ico/ico_partner_lotte.png') no-repeat center center;
}
[class*='ico'][class*='samsung'] {
  background: url('../../images/ico/ico_partner_samsung.png') no-repeat center center;
}
[class*='ico'][class*='noti_msg'] {
  background: url('../../images/ico/ico_noti_msg.png') no-repeat center center;
}
[class*='ico'][class*='res_won'] {
  background: url('../../images/ico/ico_money_won.png') no-repeat center center;
  background-size: 32px !important;
}
[class*='ico'][class*='notice'] {
  background: url('../../images/ico/ico_notice.svg') no-repeat center center;
  background-size: 18px auto;
}
[class*='ico'][class*='move'] {
  background: url('../../images/ico/ico_page_move.png') no-repeat center center;
}
[class*='ico'][class*='info'] {
  background: url('../../images/ico/ico_noti_msg.png') no-repeat center center;
}
[class*='ico'][class*='texarrow'] {
  background: url('../../images/ico/ico_texbtn_arrow.png') no-repeat center center;
}
[class*='ico'][class*='texarrow_wh'] {
  background: url('../../images/ico/ico_texbtn_arrow_wh.png') no-repeat center center;
}

[class*='ico'][class*='16'] {
  width: 16px;
  height: 16px;
  background-size: 16px;
}
[class*='ico'][class*='18'] {
  width: 18px;
  height: 18px;
  background-size: 18px;
}
[class*='ico'][class*='20'] {
  width: 20px;
  height: 20px;
  background-size: 20px;
}
[class*='ico'][class*='22'] {
  width: 22px;
  height: 22px;
  background-size: 22px;
}
[class*='ico'][class*='24'] {
  width: 24px;
  height: 24px;
  background-size: 24px;
}
[class*='ico'][class*='32'] {
  width: 32px;
  height: 32px;
  background-size: 32px;
}
[class*='ico'][class*='36'] {
  width: 36px;
  height: 36px;
  background-size: 36px;
}
[class*='ico'][class*='40'] {
  width: 40px;
  height: 40px;
  background-size: 40px;
}
[class*='ico'][class*='60'] {
  width: 60px;
  height: 60px;
  background-size: 60px;
}

// tablet
@media screen and (max-width: 1024px) {
  [class*='btn'][class*='_l']:not(.btn_layout) {
    line-height: 140%;
  }

  [class*='btn'][class*='_m'] {
    padding: 12px 16px;
    border-radius: 6px;
  }
  .btn_radius {
    @include typo_body2;
    padding: 10px 18px;
  }
}

// mobile
@media screen and (max-width: 768px) {
  [class*='btn'][class*='_l']:not(.btn_layout) {
    padding: 16px 24px;
    font-weight: 700;
    font-size: 18px;
    line-height: 135%;
  }
  [class*='btn'][class*='_m'] {
    font-weight: 500;
  }
  [class*='btn'][class*='_primary'][class*='_m'] {
    font-weight: 500;
  }
}
