.qna_write, .review_write {
  max-width: 1200px;
  .form {
    .label3 {
      width: 150px;
      flex-shrink:0;
    }
    .textarea_wrap textarea{
      height: 300px;
      width: 100%;
      border : 0;
      resize: none;
    }
    .input_checkbox_basic{
      margin: 20px 0;
    }
  }
  .btn_layout {
    margin: 20px 0;
  }

  @media screen and (max-width: 768px) {     
    .form_wrap {
      &.line_ty {
        textarea {
          @include typo_body1;
          width: calc(100% - 92px);
          font-weight: 700;
          &::placeholder {
            @include typo_body1;
          }
        }
      }
      .label3 {
        width:100px;
      }
    }
  }
}

.qna_write, .review_write{
  .form_wrap {
    .input_wrap{  
      align-items: center; 
      padding-bottom:5px;
      .textarea_txt{
        font-family: pretendard, 'Pretendard Variable', 'Pretendard-Regular', sans-serif;
        font-size:20px;        
      }  
      .input_txt2,.textarea_txt {
        flex-grow:1;
        width:calc(100% -150px);
      }   
    }
  }
  .form_wrap + .form_wrap{
    margin-top:10px;
  }
  .form_wrap.typeTop{
    .input_wrap{ 
      align-items:start;
      .label3{
        margin-top:3px;
      }
    }
  }
}


