.contact {
  > .visual{
    justify-content: center !important;
  }
  > .inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    z-index: 0;
  }
  .txt_wrap {
    width: calc(50%);
    .sub {
      color: $color_gray6;
    }
    .tlt {
      @include typo_header2;
      display: inline-block;
      margin-top: 20px;
      width: 100%;
    }
  }
  .img_wrap {
    height: 840px;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
  .contact_list {
    position: absolute;
    bottom: 88px;
    left: 40px;
    z-index: 1;
    width: calc(50% - 138px);
    .list {
      padding: 48px 0 48px 100px;
      .tlt {
        @include typo_header4;
        line-height: 145%;
        color: $color_black;
      }
      [class*='btn'] {
        display: flex;
        align-items: center;
        width: fit-content;
        margin-top: 20px;
      }
      [class*='btn'] + [class*='btn'] {
        margin-top: 4px;
      }
      & + .list {
        border-top: 1px solid rgba($color: $color_black, $alpha: 0.1);
      }
    }
    @for $list from 1 to 4 {
      .list:nth-child(#{$list}) {
        background: url('../../images/ico/ico_inquiry_' + $list + '.png') no-repeat left center;
        background-size: 60px;
      }
    }
  }
  .img_wrap {
    width: calc(50% - 20px);
  }

  //   tablet
  @media screen and (min-width: 769px) and (max-width: 1180px) {
    .txt_wrap {
      width: 100%;
    }
    .img_wrap {
      margin-top: 48px;
      margin-right: -40px;
      width: calc(100% + 40px);
      height: auto;
    }
    .contact_list {
      position: static;
      width: 100%;
    }
  }
  //   mobile
  @media screen and (max-width: 768px) {
    .txt_wrap {
      width: 100%;
      .sub {
        @include typo_body2;
        line-height: 165%;
      }
      .tlt {
        margin-top: 12px;
        font-size: 30px;
        line-height: 125%;
      }
    }
    .img_wrap {
      margin-top: 48px;
      margin-right: -24px;
      width: calc(100% + 24px);
      height: auto;
    }
    .contact_list {
      margin-top: 24px;
      position: static;
      width: 100%;
      .list {
        padding: 24px 0 24px 76px;
        .tlt {
          font-size: 18px;
          line-height: 135%;
        }
        .desc {
          @include typo_body2;
          margin-top: 4px;
          line-height: 165%;
        }
        [class*='bk'] {
          margin-top: 12px;
          span {
            @include typo_body2;
            font-weight: 600;
            line-height: 165%;
          }
        }
      }
    }
    .mo_view {
      display: block !important;
    }
  }
}
